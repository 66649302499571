import { Prop } from 'vue-property-decorator';
import { link } from '~/utils/molecules';
import { VueComponent } from '~/utils/vue-component';

interface AbstractMapInterface {
  items: ForeignRepresentationLink[];
}

export interface ForeignRepresentationLink {
  countryList: string[];
  link: string;
}

export default class AbstractMap extends VueComponent<AbstractMapInterface>
  implements AbstractMapInterface {
  @Prop({ type: Array, required: true })
  public items!: ForeignRepresentationLink[];

  protected representations: string[] = [];

  protected get representationLinks(): { [key: string]: string | null } {
    const repres: { [key: string]: string | null } = {};
    this.representations.forEach((representation) => {
      repres[representation] = this.getRepresentationLink(representation);
    });
    return repres;
  }

  protected getRepresentationLink(code: string): string | null {
    const repreLink = this.items.filter(
      (item) => item.countryList.indexOf(code) > -1
    );
    if (repreLink && repreLink.length > 0) {
      return repreLink[0].link;
    }
    return null;
  }

  protected handleClick(url: string, locale: string) {
    if (link.isInternal(url)) {
      this.$router.push(link.createInternal(url, locale));
    } else if (window) {
      window.location.href = link.createExternal(url);
    }
  }
}
