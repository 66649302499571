import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { CztWidgets } from '~/utils/views/widgets';
import Europe from '../../maps/Europe';
import America from '../../maps/America';
import Asia from '../../maps/Asia';

import style from './RepresentationMap.scss';
import { ForeignRepresentationLink } from '../../maps/AbstractMap';
import { Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import { RepresentationMapInterface } from './types';

const rootClass = 'czt-representation-map';

@Component({
  style,
})
export default class RepresentationMap
  extends VueComponent<RepresentationMapInterface>
  implements RepresentationMapInterface {
  @Prop()
  public anchorId?: string;

  @Prop({ required: true, type: String })
  public title!: string;

  @Prop({ required: true, type: String })
  public continent!: string;

  @Prop({ required: true, type: String })
  public guid!: string;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ required: true })
  public items!: ForeignRepresentationLink[];

  public className = CztWidgets.TRADE_OFFFERS;

  protected get mapClasses(): string[] {
    const classes = [rootClass];
    switch (this.continent.toLowerCase()) {
      case 'europe':
        classes.push(`${rootClass}--europe`);
        break;
      case 'america':
        classes.push(`${rootClass}--america`);
        break;
      case 'asia':
        classes.push(`${rootClass}--asia`);
        break;
    }
    return classes;
  }

  protected get continentElement(): JSX.Element {
    switch (this.continent.toLowerCase()) {
      case 'america':
        return <America class={`${rootClass}__canvas`} items={this.items} />;
      case 'asia':
        return <Asia class={`${rootClass}__canvas`} items={this.items} />;
      case 'europe':
        return <Europe class={`${rootClass}__canvas`} items={this.items} />;
      default:
        return <div />;
    }
  }

  protected get cols(): number {
    switch (this.continent.toLowerCase()) {
      case 'america':
        return 6;
      case 'asia':
        return 8;
      case 'europe':
      default:
        return 12;
    }
  }

  protected render() {
    return (
      <v-sheet id={this.anchorId} class={this.mapClasses.join(' ')}>
        {this.renderContent()}
      </v-sheet>
    );
  }

  protected renderContent() {
    const containerClasses = ['czt-spacer'];

    if (this.isTopSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-top');
    }
    if (this.isBottomSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-bottom');
    }

    return (
      <v-container class={containerClasses.join(' ')}>
        <div class='pt-3 pb-0 px-0'>
          {this.title.trim() !== '' && (
            <v-row>
              <v-col class='py-0'>
                <Headline underscore align={Align.LEFT} level={2}>
                  {this.title}
                </Headline>
              </v-col>
            </v-row>
          )}
          <v-row class='d-none d-lg-block'>
            <v-col cols={this.cols}>{this.continentElement}</v-col>
          </v-row>
        </div>
      </v-container>
    );
  }
}
